import { Display } from '@/constants/enum';
import styled from 'styled-components';
interface IPositionIcon {
  scale: number;
  positionValue: number;
  bottom: number;
  groupColor?: string;
  display: Display;
  borderRadius: string;
  tooltipBackgroundColor: string;
  tooltipTextColor: string;
}

interface IIconProps {
  backgroundColor?: string;
  scale: number;
  borderRadius: string;
  display: Display;
  tooltipBackgroundColor: string;
  tooltipTextColor: string;
  positionValue: number;
  iconColor?: string;
  totalNumberOfProductsText?: string;
  totalNumberOfProductsBackground?: string;
}

export const IconStyled = styled.div<IIconProps>`
  width: ${(props) => props.scale}px !important;
  height: ${(props) => props.scale}px !important;
  position: relative; /* Để có thể định vị tooltip */
  display: inline-block;

  .tooltip {
    font-size: ${(props) => {
      if (props.scale < 30) return '13px';
      if (props.scale < 40 && props.scale >= 30) return '14px';
      if (props.scale < 60 && props.scale >= 40) return '15px';
      if (props.scale >= 60) return '15px';
    }};
    visibility: hidden; /* Ẩn tooltip mặc định */
    position: absolute;
    white-space: nowrap;
    background-color: ${(props) => props.tooltipBackgroundColor};
    color: ${(props) => props.tooltipTextColor};
    box-shadow: 0 2px 7px #00000026;
    padding: 2px 20px;
    border-radius: 10px;
    bottom: ${(props) =>
      props.display === Display.Vertical
        ? `${props.scale / 2 - 24}px`
        : '100%'}; /* Đặt vị trí tooltip ở phía trên của phần tử cha */
    ${(props) =>
      props.display === Display.Vertical
        ? props.positionValue < 50
          ? `right: ${props.scale + 8}px;`
          : `left:${props.scale + 8}px;`
        : 'left : 50%;'}
    transform: ${(props) => (props.display === Display.Vertical ? 'translate(0%, -50%)' : 'translate(-50%, -50%)')};
  }
  &:hover .tooltip {
    visibility: visible;
  }

  img {
    border-radius: ${(props) => props.borderRadius};
  }

  .wrap-cart-btn-preview {
    position: relative;
    &::after {
      content: '3';
      position: absolute;
      top: -5px;
      left: -5px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      color: ${(props) => props.totalNumberOfProductsText || 'white'};
      background-color: ${(props) => props.totalNumberOfProductsBackground || 'red'};
    }
  }
`;
export const PreviewStyled = styled.div<IPositionIcon>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  height: fit-content;
  .widget-hidden {
    opacity: 0;
    transform: rotate(0deg);
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    /* transition: 0.5s; */
  }
  .widget-active {
    transform: rotate(360deg);
    position: relative;
    opacity: 1;
    transition: 0.5s;
    width: ${(props) => props.scale}px !important;
    height: ${(props) => props.scale}px !important;
    img {
      width: ${(props) => props.scale}px !important;
      height: ${(props) => props.scale}px !important;
    }
    & > svg {
      box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    }
  }

  .list-channels-button-active {
    svg {
      box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    }
    justify-content: flex-end;
    ${(props) => (props.display === Display.Horizontal ? 'column-gap: 0.5rem;' : 'row-gap: 0.5rem;')}
    transition: 0.5s;
    max-height: ${(props) => `calc(300px - ${props.bottom * 3}px - ${props.scale}px - 16px)`};
    max-width: ${(props) =>
      `calc(300px - ${Math.min(props.positionValue, 100 - props.positionValue) * 3}px - ${props.scale}px - 16px)`};
  }
  .list-channels-button-hidden {
    ${(props) => (props.display === Display.Horizontal ? 'column-gap: 0rem;' : 'row-gap: 0rem;')}
    justify-content: flex-start;
    transition: 0.5s;
  }
  fill: ${(props) => props.groupColor};
  .icon-close {
    display: flex;
    fill: ${(props) => props.groupColor};
  }
  .list-channels-button {
    display: flex;
    ${(props) =>
      props.display === Display.Horizontal
        ? `${props.positionValue > 51 ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}`
        : 'flex-direction: column;'}
    align-items: flex-end;
    cursor: pointer;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    overflow: visible;
  }
  .position-icon {
    display: flex;
    ${(props) =>
      props.display === Display.Horizontal
        ? `flex-direction: ${props.positionValue > 50 ? 'row-reverse;' : 'row'}`
        : 'flex-direction: column;'}
    justify-content: end;
    align-items: flex-end;
    position: absolute;
    column-gap: 0.5rem;
    ${(props) => {
      if (props.positionValue < 50) {
        return `right : ${props.positionValue}%;`;
      } else {
        return `left : ${100 - props.positionValue}%;`;
      }
    }}
    bottom: ${(props) => props.bottom}%;
    svg {
      border-radius: ${(props) => props.borderRadius};
    }
  }

  .tv-screen {
    position: relative;
    width: 100%;
    height: 500px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    border-radius: 5px;
    overflow: hidden;
    border: 0.5px solid #a1a1a1;
  }

  .tv-screen .phone-mode {
    transform: scale(0.5);
  }
  .selected-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }

  .view-switch {
    margin-top: 28px;
  }
  .view-switch Button {
    border: none;
  }

  .mobile-view {
    width: 200px;
  }
  .widget-tooltip-contaner {
    position: relative;
  }
  .widget-tooltip {
    font-size: ${(props) => {
      if (props.scale < 30) return '13px';
      if (props.scale < 40 && props.scale >= 30) return '14px';
      if (props.scale < 60 && props.scale >= 40) return '15px';
      if (props.scale >= 60) return '15px';
    }};
    visibility: hidden; /* Ẩn tooltip mặc định */
    position: absolute;
    white-space: nowrap;
    background-color: ${(props) => props.tooltipBackgroundColor};
    color: ${(props) => props.tooltipTextColor};
    box-shadow: 0 2px 7px #00000026;
    padding: 2px 20px;
    border-radius: 10px;
    bottom: ${(props) => props.scale / 2 - 12}px; /* Đặt vị trí tooltip ở phía trên của phần tử cha */
    ${(props) => (props.positionValue < 50 ? `right: ${props.scale + 8}px;` : `left:${props.scale + 8}px;`)}
  }
  .widget-tooltip-container:hover {
    .widget-tooltip {
      visibility: visible;
    }
  }

  .close-icon-tooltip {
    font-size: ${(props) => {
      if (props.scale < 30) return '13px';
      if (props.scale < 40 && props.scale >= 30) return '14px';
      if (props.scale < 60 && props.scale >= 40) return '15px';
      if (props.scale >= 60) return '15px';
    }};
    visibility: hidden; /* Ẩn tooltip mặc định */
    position: absolute;
    white-space: nowrap;
    background-color: ${(props) => props.tooltipBackgroundColor};
    color: ${(props) => props.tooltipTextColor};
    box-shadow: 0 2px 7px #00000026;
    padding: 2px 20px;
    border-radius: 10px;
    bottom: ${(props) =>
      props.display === Display.Vertical
        ? `${props.scale / 2 - 24}px`
        : '100%'}; /* Đặt vị trí tooltip ở phía trên của phần tử cha */
    ${(props) =>
      props.display === Display.Vertical
        ? props.positionValue < 50
          ? `right: ${props.scale + 8}px;`
          : `left:${props.scale + 8}px;`
        : 'left : 50%;'}
    transform: ${(props) => (props.display === Display.Vertical ? 'translate(0%, -50%)' : 'translate(-50%, -50%)')};
  }
  .close-icon-container {
    position: relative;
  }
  .close-icon-container:hover {
    .close-icon-tooltip {
      visibility: visible;
    }
  }

  /* .hidden {
    display: none;
  } */

  /* CSS */
`;
