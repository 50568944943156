import dashboard from './dashboard.png';
import grid from './grid.jpg';
import omegaChatEdit from './omegachatedit.gif';
import checkedIcon from './checked-icon.png';
import crown from './crown.svg';
import returnPopup from './modal-return.png';
import estimatedPopup from './modal-estimated.png';
const order_tracking_banner_app = 'https://megamind-estimated.s3.amazonaws.com/asset/apps/order_tracking_banner.webp';
const order_tracking_app = 'https://megamind-estimated.s3.amazonaws.com/asset/apps/ordertracking.webp';
const return_drive_banner_app = 'https://megamind-estimated.s3.amazonaws.com/asset/banner/banner_return.png';
const return_drive_app = 'https://megamind-assets.s3.us-west-1.amazonaws.com/assets/logo_return.png';
const dashboardSynctrack = 'https://megamind-assets.s3.us-west-1.amazonaws.com/Synctrack/synctrack_banner.png';
const blockify = 'https://megamind-chat-buttons.s3.amazonaws.com/assets/blockify_crosssell_chat.png';
const synctrack_banner = 'https://megamind-estimated.s3.amazonaws.com/asset/images/synctrack_banner.png';
const embed_gif = 'https://megamind-estimated.s3.amazonaws.com/asset/images/embed_gif.gif';
const emptyState = 'https://megamind-estimated.s3.amazonaws.com/asset/images/emptystate-files.avif';
const emptyStateFormAnswer = 'https://megamind-chat-buttons.s3.amazonaws.com/assets/chat_empty.png';
const preview_product = 'https://megamind-estimated.s3.amazonaws.com/asset/images/preview_product.png';
const demo_estimated_text_cart = 'https://megamind-estimated.s3.amazonaws.com/asset/images/demo_estimated_text_cart.png';

// onboarding
const welcome = 'https://megamind-estimated.s3.amazonaws.com/asset/images/welcome.png';
const onboarding_demo_page = 'https://megamind-estimated.s3.amazonaws.com/asset/images/onboarding_demo_page.png';
const onboarding_finish = 'https://megamind-estimated.s3.amazonaws.com/asset/images/onboarding_finish.png';
const onboarding_method = 'https://megamind-estimated.s3.amazonaws.com/asset/images/onboarding_method.png';
const onboarding_zipcode = 'https://megamind-estimated.s3.amazonaws.com/asset/images/onboarding_zipcode.png';
const onboarding_country = 'https://megamind-estimated.s3.amazonaws.com/asset/images/onboarding_country.png';
const blank = 'https://megamind-estimated.s3.amazonaws.com/asset/images/blank.png';
const basic_method = 'https://megamind-estimated.s3.amazonaws.com/asset/images/basic_method.png';
const basic_zipcode = 'https://megamind-estimated.s3.amazonaws.com/asset/images/basic_zipcode.png';
const basic_country = 'https://megamind-estimated.s3.amazonaws.com/asset/images/basic_country.png';
const advanced_method = 'https://megamind-estimated.s3.amazonaws.com/asset/images/advanced_method.png';
const advanced_zipcode = 'https://megamind-estimated.s3.amazonaws.com/asset/images/advanced_zipcode.png';
const advanced_country = 'https://megamind-estimated.s3.amazonaws.com/asset/images/advanced_country.png';

// recommendApp
const estimated_delivery = 'https://megamind-assets.s3.us-west-1.amazonaws.com/assets/logo_estimate.png';
const banner_estimated_delivery = 'https://megamind-estimated.s3.amazonaws.com/asset/banner/banner_est.png';
const app_quantity_break_1 = 'https://megamind-chat-buttons.s3.us-east-1.amazonaws.com/assets/popup_quantity1.png';
const app_quantity_break_2 = 'https://megamind-chat-buttons.s3.us-east-1.amazonaws.com/assets/popup_quantity2.png';
const app_quantity_break_3 = 'https://megamind-chat-buttons.s3.us-east-1.amazonaws.com/assets/popup_quantity3.png';

//pricing
const omegachat_free = 'https://megamind-chat-buttons.s3.amazonaws.com/assets/omegachat_free.webp';

export const images = {
  app_quantity_break_1,
  app_quantity_break_2,
  app_quantity_break_3,
  dashboard,
  dashboardSynctrack,
  blockify,
  order_tracking_banner_app,
  order_tracking_app,
  return_drive_banner_app,
  return_drive_app,
  welcome,
  synctrack_banner,
  embed_gif,
  onboarding_demo_page,
  onboarding_finish,
  emptyState,
  preview_product,
  onboarding_method,
  onboarding_zipcode,
  onboarding_country,
  blank,
  basic_method,
  basic_zipcode,
  basic_country,
  advanced_method,
  advanced_zipcode,
  advanced_country,
  demo_estimated_text_cart,
  estimated_delivery,
  banner_estimated_delivery,
  grid,
  omegaChatEdit,
  checkedIcon,
  crown,
  omegachat_free,
  emptyStateFormAnswer,
  returnPopup,
  estimatedPopup,
};
