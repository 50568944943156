import { images } from '@/asset';
import BaseTable from '@/components/BaseTable';
import BoldText from '@/components/BoldText';
import DatePicker from '@/components/DatePicker';
import Layout from '@/components/layout';
import ModalConfirm from '@/components/ModalConfirm';
import ModalSelectWidget from '@/components/ModalSelectWidget';
import PopoverWithListbox, { ISegment } from '@/components/PopoverWithListbox';
import RegularText from '@/components/RegularText';
import SessionBanner from '@/components/SessionBanner';
import { ESubscriptionKey, ICon, SortDirection, Subscription } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { formatDate } from '@/helpers/time';
import { useUserPlan } from '@/hooks/useUserPlan';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import formAnswerSlice, {
  ESubscriptionFilter,
  IFormAnswerParams,
  formAnswerDataSelector,
  formAnswerParamsSelector,
  titleBtnDatePickerSelector,
} from '@/redux/slice/formAnswer.slice';
import toastSlice from '@/redux/slice/toast.slice';
import {
  Badge,
  Button,
  FilterInterface,
  Icon,
  IndexFilters,
  IndexFiltersProps,
  IndexTable,
  LegacyCard,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { CheckIcon, DeleteIcon, ExportIcon, PlusCircleIcon, ViewIcon } from '@shopify/polaris-icons';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Headers } from 'react-csv/lib/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormAnswerStyled } from './styled';

const startTime = new Date().getTime();

export default function FormAnswer() {
  const { mode, setMode } = useSetIndexFiltersMode();
  const formAnswerParams = useSelector(formAnswerParamsSelector);
  const formAnswerData = useSelector(formAnswerDataSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  // const isVisibleGrantPermission = useSelector(isVisibleGrantPermissionSelector);
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const navigate = useNavigate();
  const { isLimitedFormAnswer } = useUserPlan();
  // const { data: dataSettings } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const [buttonGroupOptions, setButtonGroupOptions] = useState<ISegment[]>([]);
  const [queryValue, setQueryValue] = useState<string>('');
  const [isChangeQueryValue, setIsChangeQueryValue] = useState<boolean>(false);
  const [isOpenModalSelectWidget, setIsOpenModalSelectWidget] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<{ id?: number; isOpen: boolean }>({ isOpen: false });

  const { data: dataButtonsRes, isLoading: dataButtonsLoading } = apiCaller.useGetAllButtonsQuery(undefined, { skip: isSkip });
  const { data, isFetching, isLoading } = apiCaller.useGetListsFormAnswerQuery(
    {
      buttonId: formAnswerParams.buttonId ? +formAnswerParams.buttonId : undefined,
      endTime: new Date(formAnswerParams.endDate).getTime(),
      startTime: new Date(formAnswerParams.startDate).getTime(),
      page: 1,
      perPage: -1,
      sortDirection: formAnswerParams.sortDirection,
      value: formAnswerParams.value || undefined,
    },
    { skip: isSkip },
  );

  const { data: dataButtonGroup, isLoading: isLoadingButtonGroup } = apiCaller.useListGroupButtonQuery(
    {
      endTime: startTime,
      startTime: new Date('01/01/2014').getTime(),
      page: 1,
      perPage: -1,
      sortDirection: SortDirection.Asc,
    },
    { skip: isSkip },
  );

  // const { data: urlUpdateScope } = apiCaller.useRedirectUpdateShopScopeQuery(
  //   {
  //     scope: Scope.WriteCustomers,
  //   },
  //   {
  //     skip: isSkip,
  //   },
  // );

  const isHadForm = useMemo(() => {
    if (!dataButtonGroup?.data) return false;
    return dataButtonGroup?.data.some((item) => item?.buttons?.some((button) => button.channel === ICon.ContactForm));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataButtonGroup?.data)]);

  useEffect(() => {
    let res = data?.data;

    if (formAnswerParams.subscription) {
      res = res?.filter((item) => {
        const answer = JSON.parse(item.answer);
        switch (formAnswerParams.subscription) {
          case ESubscriptionFilter.Subscribed:
            return answer?.[ESubscriptionKey.Email] === 'on' || answer?.[ESubscriptionKey.SMS] === 'on';
          case ESubscriptionFilter.Email:
            return answer?.[ESubscriptionKey.Email] === 'on';
          case ESubscriptionFilter.SMS:
            return answer?.[ESubscriptionKey.SMS] === 'on';
          case ESubscriptionFilter.NotSubscribed:
            // answer that show consent but customer not subscribed
            return (
              (answer?.[ESubscriptionKey.Email] === 'off' || answer?.[ESubscriptionKey.Email] === undefined) &&
              (answer?.[ESubscriptionKey.SMS] === 'off' || answer?.[ESubscriptionKey.SMS] === undefined) &&
              !(answer?.[ESubscriptionKey.Email] === undefined && answer?.[ESubscriptionKey.SMS] === undefined)
            );
          default:
            return true;
        }
      });
    }
    res = res?.slice((formAnswerParams.page - 1) * formAnswerParams.perPage, formAnswerParams.page * formAnswerParams.perPage);
    dispatch(formAnswerSlice.actions.handleSetFormAnswerData(res));
  }, [data?.data, dispatch, formAnswerParams.page, formAnswerParams.perPage, formAnswerParams.subscription]);

  const handleUpdateFilter = useCallback(
    (value: IFormAnswerParams) => {
      dispatch(formAnswerSlice.actions.handleSetFormAnswerParams(value));
    },
    [dispatch],
  );

  const [handleDeleteFormAnswer, { isLoading: isDeleting }] = apiCaller.useDeleteFormAnswerMutation();

  useEffect(() => {
    if (dataButtonsRes) {
      const optionButtonGroups = dataButtonsRes?.data?.map((button) => ({
        value: button.buttonId + '',
        label: button.groupName,
      }));
      setButtonGroupOptions(optionButtonGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataButtonsRes]);

  const handleGroupButtonChange = useCallback(
    (value: string[]) => {
      handleUpdateFilter({ ...formAnswerParams, buttonId: value[0], page: 1 });
    },
    [handleUpdateFilter, formAnswerParams],
  );
  const handleOrderDirectionChange = useCallback(
    (value: SortDirection) => {
      handleUpdateFilter({ ...formAnswerParams, sortDirection: value, page: 1 });
    },
    [handleUpdateFilter, formAnswerParams],
  );
  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    setIsChangeQueryValue(true);
  }, []);
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      isChangeQueryValue && handleUpdateFilter({ ...formAnswerParams, value: queryValue, page: 1 });
      setIsChangeQueryValue(false);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryValue), JSON.stringify(handleUpdateFilter), JSON.stringify(formAnswerParams)]);

  const handleButtonGroupRemove = useCallback(() => {
    handleUpdateFilter({ ...formAnswerParams, buttonId: undefined, page: 1 });
  }, [handleUpdateFilter, formAnswerParams]);

  const handleSortRemove = useCallback(() => {
    handleUpdateFilter({ ...formAnswerParams, sortDirection: SortDirection.Desc, page: 1 });
  }, [handleUpdateFilter, formAnswerParams]);

  const sortOptions: IndexFiltersProps['sortOptions'] = [
    { label: 'Created At', value: 'createdAt asc', directionLabel: 'Ascending' },
    { label: 'Created At', value: 'createdAt desc', directionLabel: 'Descending' },
  ];

  const filters: FilterInterface[] = [
    // {
    //   key: 'color',
    //   label: 'Button groups',
    //   filter: (
    //     <ChoiceList
    //       title="Button groups"
    //       titleHidden
    //       choices={buttonGroupOptions}
    //       selected={[formAnswerParams.buttonId || '']}
    //       onChange={handleGroupButtonChange}
    //     />
    //   ),
    //   shortcut: true,
    // },
  ];

  const appliedFilters = [];
  if (formAnswerParams.buttonId) {
    const key = 'buttonGroups';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, [formAnswerParams.buttonId]),
      onRemove: handleButtonGroupRemove,
    });
  }
  if (formAnswerParams.sortDirection) {
    const key = 'orderDirection';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, [formAnswerParams.sortDirection]),
      onRemove: handleSortRemove,
    });
  }

  function disambiguateLabel(key: string, value: string[]): string {
    switch (key) {
      case 'buttonGroups':
        return value
          .map((val) => {
            const selectedGroup = buttonGroupOptions.find((option) => option.value === val)?.label || '';
            return `Button groups: ${selectedGroup}`;
          })
          .toString();
      default:
        return value.toString();
    }
  }

  const handleOpenModalDelete = (id: number) => {
    setModalDelete({
      isOpen: true,
      id,
    });
  };

  const handleConfirmDelete = () => {
    handleDeleteFormAnswer({ id: modalDelete.id as number }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
      setModalDelete({
        id: 0,
        isOpen: false,
      });
    });
  };

  const tableConfig: {
    headings: NonEmptyArray<IndexTableHeading>;
    rowMarkup: (JSX.Element | null)[] | undefined;
  } = useMemo(() => {
    const handleRenderSubscription = (subscription: 'on' | 'off' | undefined) => {
      switch (subscription) {
        case 'on':
          return <Badge tone="success">Subscribed</Badge>;

        case 'off':
          return <Badge>Pending</Badge>;

        default:
          return <></>;
      }
    };

    const formFields = dataButtonsRes?.data?.find((item) => item.buttonId === +(formAnswerParams.buttonId || '-1'))?.formFields;
    const advance = dataButtonsRes?.data?.find((item) => item.buttonId === +(formAnswerParams.buttonId || '-1'))?.advance;
    const headings: IndexTableHeading[] = [];
    const rowMarkup = formAnswerData?.map((dataAnswer, index) => {
      if (dataAnswer) {
        const dataRender: { key: string; value: any }[] = [];
        const answer = JSON.parse(dataAnswer.answer);
        for (let key in answer) {
          if (![ESubscriptionKey.Email as string, ESubscriptionKey.SMS as string].includes(key)) {
            dataRender.push({ key, value: answer[key] });
          }
        }
        return (
          <IndexTable.Row id={index.toString()} key={dataAnswer.id} position={index}>
            {/* <IndexTable.Cell className="answer-cell">
              <ExpandData dataRender={dataRender} numberRowsDefaultDisplay={3} rowId={dataAnswer.id} />
            </IndexTable.Cell> */}
            {formAnswerParams.buttonId ? (
              <>
                {formFields
                  ? formFields?.map((item) => {
                      const data = dataRender.find((i) => i.key === item.title);
                      return <IndexTable.Cell key={item.title}>{data?.value}</IndexTable.Cell>;
                    })
                  : null}
                {advance?.formAgreeEmailSubscription && answer?.[ESubscriptionKey.Email] ? (
                  <IndexTable.Cell>{handleRenderSubscription(answer?.[ESubscriptionKey.Email])}</IndexTable.Cell>
                ) : (
                  <IndexTable.Cell>---</IndexTable.Cell>
                )}
                {advance?.formAgreeSMSSubscription && answer?.[ESubscriptionKey.SMS] ? (
                  <IndexTable.Cell>{handleRenderSubscription(answer?.[ESubscriptionKey.SMS])}</IndexTable.Cell>
                ) : (
                  <IndexTable.Cell>---</IndexTable.Cell>
                )}
              </>
            ) : (
              <>
                <IndexTable.Cell>{answer?.Name}</IndexTable.Cell>
                <IndexTable.Cell>{answer?.Email}</IndexTable.Cell>
                <IndexTable.Cell>{answer?.Phone}</IndexTable.Cell>
                <IndexTable.Cell>{handleRenderSubscription(answer?.[ESubscriptionKey.Email])}</IndexTable.Cell>
              </>
            )}
            <IndexTable.Cell className="name-cell">{formatDate(dataAnswer?.createdAt)}</IndexTable.Cell>
            <IndexTable.Cell className="actions-cell">
              <div className="d-flex justify-center">
                <div className="btn-action">
                  <Tooltip content="View">
                    <Button
                      onClick={() =>
                        navigate({
                          ...PATH.FORM_ANSWER_DETAIL,
                          pathname: PATH.FORM_ANSWER_DETAIL.pathname.replace(':id', dataAnswer.id.toString()),
                        })
                      }
                      variant="plain"
                      icon={ViewIcon}
                    />
                  </Tooltip>
                </div>
                <div className="btn-action btn-delete">
                  <Button onClick={() => handleOpenModalDelete(dataAnswer.id)} variant="plain" icon={DeleteIcon}></Button>
                </div>
              </div>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      }
      return null;
    });

    if (formAnswerParams.buttonId) {
      formFields?.forEach((item) => headings.push({ title: item.title }));
      if (advance?.formAgreeEmailSubscription) {
        headings.push({ title: 'Email Subscription' });
      }
      if (advance?.formAgreeSMSSubscription) {
        headings.push({ title: 'SMS Subscription' });
      }
    } else {
      headings.push({ title: 'Name' }, { title: 'Email' }, { title: 'Phone' }, { title: 'Email Subscription' });
    }

    headings.push({ title: 'Form created' }, { title: 'Actions', alignment: 'center' });

    return { headings: headings as NonEmptyArray<IndexTableHeading>, rowMarkup };
  }, [dataButtonsRes?.data, formAnswerData, formAnswerParams.buttonId, navigate]);

  const changePage = (page: number) => {
    handleUpdateFilter({ ...formAnswerParams, page });
  };

  // const handleGrantPermission = () => {
  //   urlUpdateScope?.data?.url && window.open(urlUpdateScope.data.url, '_blank');
  // };

  const selectedSubscriptionFilter = useMemo(() => formAnswerParams.subscription, [formAnswerParams.subscription]);

  const handleChangeSelectedSubscriptionFilter = (value?: ESubscriptionFilter, cancelValue?: ESubscriptionFilter) => {
    dispatch(
      formAnswerSlice.actions.handleSetFormAnswerParams({
        ...formAnswerParams,
        subscription: formAnswerParams.subscription === value ? cancelValue : value,
      }),
    );
  };

  const dataAnswer = useMemo(() => {
    const header: Headers | undefined = [{ label: 'Widget', key: 'widget' }];

    const res: any[] = [];
    formAnswerData?.forEach((dataAnswer) => {
      if (dataAnswer) {
        const answer = JSON.parse(dataAnswer.answer);
        res.push({ widget: dataAnswer.groupName, ...answer });
        for (let key in answer) {
          if (!header.some((item) => item.key === key)) header.push({ label: key, key });
        }
      }
    });
    return { header, data: res };
  }, [formAnswerData]);

  return (
    <Layout title="Form Answer">
      <FormAnswerStyled>
        {isLimitedFormAnswer ? (
          <SessionBanner sessionKey="ot_chat_is_hide_contact_form_banner" title="Customizable Contact Form">
            <div>
              Easily collect feedback, inquiries, and important customer details directly through your site.
              <br />
              Unlock the full potential of customer interaction with our advanced Contact Form.
            </div>
            <div className="mt-8">
              <Button
                variant="primary"
                onClick={() => {
                  navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
                }}
              >
                Upgrade Now
              </Button>
            </div>
          </SessionBanner>
        ) : null}
        {/* {!dataSettings?.data?.enabledWriteCustomer && isVisibleGrantPermission ? (
          <InformationBanner
            isVisible={true}
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleGrantPermission', value: false }));
            }}
          >
            <RegularText>
              We’re introducing data synchronization with Shopify for a seamless experience. Please grant your permission to
              activate this feature. This is optional.
            </RegularText>
            <div className="mt-8">
              <Button onClick={handleGrantPermission}>Grant Permission</Button>
            </div>
          </InformationBanner>
        ) : null} */}
        <div>
          <LegacyCard>
            <div className="table-button-top-group">
              <PopoverWithListbox
                activator={
                  <Button disclosure={'down'} variant="primary">
                    Widget
                  </Button>
                }
                segments={buttonGroupOptions}
                onSelect={(value) => {
                  if (value) {
                    handleGroupButtonChange([value]);
                  } else {
                    handleButtonGroupRemove();
                  }
                }}
                segmentsName="widget"
                isUncheckable
              />
              <div className="d-flex gap-14 flex-wrap">
                <Button icon={ExportIcon}>
                  {
                    (
                      <CSVLink
                        data={dataAnswer.data}
                        headers={dataAnswer.header}
                        filename="form_answers.csv"
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        Export
                      </CSVLink>
                    ) as any
                  }
                </Button>
                <DatePicker
                  titleButton={titleBtnDatePicker}
                  setTitleButton={(value: string) => dispatch(formAnswerSlice.actions.handleTitleBtnDatePicker(value))}
                  startDate={formAnswerParams.startDate}
                  endDate={formAnswerParams.endDate}
                  onSave={(start, end) =>
                    dispatch(
                      formAnswerSlice.actions.handleSetFormAnswerParams({
                        ...formAnswerParams,
                        endDate: end,
                        startDate: start,
                        page: 1,
                      }),
                    )
                  }
                />
              </div>
            </div>
            <IndexFilters
              cancelAction={{ onAction: () => {} }}
              sortOptions={sortOptions}
              sortSelected={[`createdAt ${formAnswerParams.sortDirection.toLowerCase()}`]}
              queryValue={queryValue}
              queryPlaceholder="Searching in all"
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={() => setQueryValue('')}
              onSort={(value) => {
                const direction = value[0].split(' ')[1];
                handleOrderDirectionChange(direction.toUpperCase() as SortDirection);
              }}
              tabs={[
                {
                  id: 'all-subscribed',
                  content: 'All',
                  onAction: () => {
                    handleChangeSelectedSubscriptionFilter(undefined);
                  },
                },
                {
                  id: 'subscribed',
                  content: 'Subscribed',

                  actions: [
                    {
                      id: 'subscribed-button-email',
                      content: 'Email Subscription',
                      type: 'edit',
                      onAction: () => {
                        handleChangeSelectedSubscriptionFilter(ESubscriptionFilter.Email, ESubscriptionFilter.Subscribed);
                      },
                      active: selectedSubscriptionFilter === ESubscriptionFilter.Email,
                      suffix: selectedSubscriptionFilter === ESubscriptionFilter.Email ? <Icon source={CheckIcon} /> : undefined,
                    },
                    {
                      id: 'subscribed-button-sms',
                      content: 'SMS Subscription',
                      type: 'edit',
                      onAction: () => {
                        handleChangeSelectedSubscriptionFilter(ESubscriptionFilter.SMS, ESubscriptionFilter.Subscribed);
                      },
                      active: selectedSubscriptionFilter === ESubscriptionFilter.SMS,
                      suffix: selectedSubscriptionFilter === ESubscriptionFilter.SMS ? <Icon source={CheckIcon} /> : undefined,
                    },
                  ],
                },
                {
                  id: 'not-subscribed',
                  content: 'Not Subscribed',
                  onAction: () => {
                    handleChangeSelectedSubscriptionFilter(ESubscriptionFilter.NotSubscribed);
                  },
                },
              ]}
              selected={
                selectedSubscriptionFilter === undefined
                  ? 0
                  : selectedSubscriptionFilter === ESubscriptionFilter.NotSubscribed
                  ? 2
                  : 1
              }
              onSelect={(index) => {
                switch (index) {
                  case 1:
                    handleChangeSelectedSubscriptionFilter(ESubscriptionFilter.Subscribed);
                    break;
                  default:
                    break;
                }
              }}
              filters={filters}
              appliedFilters={appliedFilters}
              onClearAll={() => {
                handleUpdateFilter({ ...formAnswerParams, buttonId: undefined, sortDirection: SortDirection.Desc, page: 1 });
              }}
              mode={mode}
              setMode={setMode}
              canCreateNewView={false}
            ></IndexFilters>
            <BaseTable
              style={{ padding: 0 }}
              headings={tableConfig.headings}
              isLoading={isLoading || dataButtonsLoading}
              itemCount={formAnswerData?.length || 0}
              isFetching={isFetching}
              onChangePage={changePage}
              page={formAnswerParams.page}
              perPage={formAnswerParams.perPage}
              rowMarkup={tableConfig.rowMarkup}
              totalOfAllItems={data?.meta?.totalResult}
              totalPages={Math.ceil((data?.data?.length || 0) / formAnswerParams.perPage)}
              emptyState={
                <div className="d-flex flex-center justify-center f-wrap">
                  <img src={images.emptyStateFormAnswer} alt="No data to display" style={{ transform: 'scale(0.7)' }} />
                  <div className="w-full text-center">
                    <BoldText>No data to display</BoldText>
                    {!isHadForm && (
                      <>
                        <RegularText>You can try this feature by creating a widget with Contact Form button</RegularText>
                        <div className="mt-16 create-widget-btn">
                          {!isLimitedFormAnswer ? (
                            <Button variant="primary" icon={PlusCircleIcon} onClick={() => setIsOpenModalSelectWidget(true)}>
                              Add Contact Form
                            </Button>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              }
            />
          </LegacyCard>
        </div>
      </FormAnswerStyled>
      <ModalConfirm
        onClose={() =>
          setModalDelete({
            id: 0,
            isOpen: false,
          })
        }
        isLoading={isDeleting}
        isOpen={modalDelete.isOpen}
        title="Do you want to delete this form answer?"
        onConfirm={handleConfirmDelete}
        sectionText="Once you delete this form answer, you will not be able to restore it."
      />
      <ModalSelectWidget
        data={dataButtonGroup}
        isLoading={isLoadingButtonGroup}
        isOpen={isOpenModalSelectWidget}
        setIsOpen={setIsOpenModalSelectWidget}
      />
    </Layout>
  );
}
