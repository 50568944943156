import styled from 'styled-components';

export const FormAnswerStyled = styled.div`
  .Polaris-TextField__ClearButton {
    display: none !important;
  }

  .paginate {
    .Polaris-Pagination {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .answer-cell {
    max-width: 300px;
    overflow: hidden;
    width: 34%;
  }

  .name-cell {
    min-width: 150px;
    .Polaris-Text--root {
      white-space: break-spaces;
    }
  }

  .actions-cell {
    width: 10%;
    max-width: 80px;
  }

  .btn-delete {
    .Polaris-Button:hover {
      svg.Polaris-Icon__Svg {
        fill: var(--p-color-icon-critical) !important;
      }
    }
  }

  .Polaris-Tag > button {
    margin-right: 5px;
  }

  .create-widget-btn svg {
    fill: #ffffff !important;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-button-top-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-bottom: var(--p-border-width-025) solid var(--p-color-border);
  }
`;
