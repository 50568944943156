import { images } from '@/asset';
import { Ga4Event } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import bannerSlice from '@/redux/slice/banner.slice';
import { Button } from '@shopify/polaris';
import { useDispatch } from 'react-redux';

interface IProps {
  onClose: () => void;
}

const EstimatedApp = ({ onClose }: IProps) => {
  const dispatch = useDispatch();
  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  return (
    <div className="d-flex gap-10 flex-column pb-8" id="modal-popup-return-drive">
      <img width={50} src={images.estimatedPopup} alt="app-return" />
      <p style={{ fontSize: '1.4rem', marginTop: '10px', marginBottom: '12px', fontWeight: 500, lineHeight: 'normal' }}>
        Did you know: Show estimated delivery date can boost conversion rate by up to 25%?
      </p>
      <p className="mt-12 mb-8">
        Provide customers with accurate delivery dates, enhancing their shopping experience and building trust. Don't let
        uncertainty hold back your sales.
      </p>
      <div className="mt-12">
        <Button
          onClick={() => {
            onClose();
            window.open(
              'https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=fbchat&utm_medium=hometop',
              '_blank',
            );
            sendGA4Event({ eventName: Ga4Event.EstimatedClick, params: {} });
            dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleModalEstimatedApp', value: false }));
          }}
          variant="primary"
        >
          Set up now
        </Button>
      </div>
    </div>
  );
};

export default EstimatedApp;
