import BoldText from '@/components/BoldText';
import CustomCard from '@/components/customCard';
import Layout from '@/components/layout';
import { ESubscriptionKey } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { formatDate } from '@/helpers/time';
import { apiCaller } from '@/redux/query';
import { formAnswerDataSelector } from '@/redux/slice/formAnswer.slice';
import { InlineGrid, TextField } from '@shopify/polaris';
import { CheckIcon, EditIcon } from '@shopify/polaris-icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormAnswerDetailStyled } from './styled';

interface IState {
  isEdit: boolean;
  note?: string;
}

const FormAnserDetail = () => {
  const navigate = useNavigate();
  const formAnswerData = useSelector(formAnswerDataSelector);
  const { id } = useParams();
  const [state, setState] = useState<IState>({
    isEdit: false,
  });

  const [updateNote, { isLoading }] = apiCaller.useUpdateNoteMutation();

  useEffect(() => {
    if (!formAnswerData || !id) {
      navigate(PATH.FORM_ANSWER);
    }
  }, [formAnswerData, id, navigate]);

  const currentAnswer = useMemo(() => {
    return formAnswerData?.find((item) => item.id === +(id || -1));
  }, [formAnswerData, id]);

  useEffect(() => {
    setState((prev) => ({ ...prev, note: currentAnswer?.note }));
  }, [currentAnswer?.note]);

  const answerData = useMemo(() => {
    if (!currentAnswer) return;
    let answer = Object.entries(JSON.parse(currentAnswer?.answer)).map(([key, value]) => ({ key, value }));

    const subscriptions = answer.filter((item) =>
      [ESubscriptionKey.Email as string, ESubscriptionKey.SMS as string].includes(item.key || ''),
    );

    answer = answer.filter((item) => !subscriptions.some((sub) => sub.key === item.key));

    return { answer, subscriptions };
  }, [currentAnswer]);

  return (
    <FormAnswerDetailStyled>
      <Layout
        title={currentAnswer?.groupName || 'Form Answer'}
        subtitle={currentAnswer?.createdAt ? `Answer forms at ${formatDate(currentAnswer?.createdAt)}` : undefined}
        backAction={{
          onAction: () => {
            navigate(PATH.FORM_ANSWER);
          },
        }}
      >
        <div className="mt-16">
          <InlineGrid columns={['twoThirds', 'oneThird']} gap={'800'}>
            <CustomCard title={'Form Answer'}>
              {answerData?.answer ? (
                <div>
                  {answerData.answer.map((item) => (
                    <div key={item.key}>
                      {item.key}: {item.value as any}
                    </div>
                  ))}

                  {answerData.subscriptions.length > 0 ? (
                    <div className="mt-16">
                      <BoldText>Subscription</BoldText>
                      <div className="mt-8">
                        {answerData.subscriptions.map((item) => {
                          const left = item.key === ESubscriptionKey.Email ? 'Email' : 'SMS';
                          const right = item.value === 'on' ? 'subscribed' : 'not subscribed';
                          return <div key={item.key}>{left + ' ' + right}</div>;
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </CustomCard>
            <CustomCard
              title={'Note'}
              secondaryActions={{
                onAction: () => {
                  if (!state.isEdit) setState((prev) => ({ ...prev, isEdit: true }));
                  else {
                    updateNote({ id: currentAnswer?.id || -1, note: state.note || '' });
                    setState((prev) => ({ ...prev, isEdit: false }));
                  }
                },
                buttonProps: {
                  icon: !state.isEdit ? EditIcon : CheckIcon,
                  variant: 'plain',
                  id: state.isEdit ? 'btn-success' : undefined,
                  loading: isLoading,
                },
              }}
            >
              {state.isEdit ? (
                <TextField
                  label=""
                  autoComplete="off"
                  multiline
                  onChange={(value) => setState((prev) => ({ ...prev, note: value }))}
                  value={state.note ?? ''}
                />
              ) : (
                state?.note || 'No notes yet.'
              )}
            </CustomCard>
          </InlineGrid>
        </div>
      </Layout>
    </FormAnswerDetailStyled>
  );
};

export default FormAnserDetail;
