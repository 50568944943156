import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const Zalo = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;
  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: 0123456789"
      tooltip={`Enter your Zalo phone number (as in "zalo.me/0123456789", use your phone number directly)`}
    />
  ) : null;
};
export default memo(Zalo);
